<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          title="개최정보"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
        >
          <template slot="table-button">
            <q-btn-group outline >
                  <c-btn 
                v-if="editable&&!disabled" 
                :isSubmit="isSaveComitteeHold"
                :url="saveComitteeHoldUrl"
                :param="moc"
                mappingType="PUT"
                label="저장" 
                icon="save" 
                @beforeAction="saveComitteeHold"
                @btnCallback="saveComitteeHoldCallback"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div v-if="Boolean(moc.committeeHold)" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="승인 상세" :disabled="!approvalEditable">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- <q-chip
                class="no-border"
                style="margin-right:5px !important" 
                square outline
                color="red" 
                text-color="white" 
                icon="report" 
                size="12px" >
                {{$language('개최정보가 하나 이상 등록 될 시')}}&nbsp;<strong>{{$language('승인')}}</strong>&nbsp;{{$language('진행하실 수 있습니다.')}}
              </q-chip> -->
              <c-btn 
                v-if="editable&&!disabled2" 
                :isSubmit="isSaveApproval"
                :url="approvalComitteeHoldUrl"
                :param="moc"
                mappingType="PUT"
                label="승인" 
                icon="approval" 
                @beforeAction="approvalComitteeHold"
                @btnCallback="approvalComitteeHoldCallback"/>
              <c-btn 
                v-if="editable&&!disabled2" 
                :isSubmit="isSaveNonApproval"
                :url="approvalComitteeHoldUrl"
                :param="moc"
                mappingType="PUT"
                label="불승인" 
                icon="do_not_disturb_on" 
                @beforeAction="nonApprovalComitteeHold"
                @btnCallback="nonApprovalComitteeHoldCallback"/>
              <c-btn 
                v-if="editable&&!disabled2" 
                :isSubmit="isSaveComitteeHoldApprovalInfo"
                :url="saveComitteeHoldUrl"
                :param="moc"
                mappingType="PUT"
                label="저장" 
                icon="save" 
                @beforeAction="saveComitteeHoldApprovalInfo"
                @btnCallback="saveComitteeHoldApprovalInfoCallback"/>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-radio 
                :disabled="true"
                :editable="editable"
                :comboItems="approvItems"
                label="승인여부"
                name="changeApprovalFlag" 
                v-model="moc.committeeHold.changeApprovalFlag">
              </c-radio>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                required
                :disabled="disabled2"
                :editable="editable"
                label="승인/불승인 처리일"
                name="changeApprovalDate"
                v-model="moc.committeeHold.changeApprovalDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                required
                :disabled="disabled2"
                :editable="editable"
                label="승인/불승인 사유"
                name="changeApprovalReason" 
                v-model="moc.committeeHold.changeApprovalReason">
              </c-textarea>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-upload 
                :attachInfo="attachInfo"
                :editable="editable&&!disabled2&&approvalEditable">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-table
      class="q-mt-sm"
      title="최종 진행항목"
      :columns="grid3.columns"
      :gridHeight="grid3.height"
      :data="moc.relatedWorks"
      :filtering="false"
      :usePaging="false"
      :columnSetting="false"
      :editable="editable&&!disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSaveRelatedWork"
            :url="saveRelatedWorkUrl"
            :param="grid3.saveData"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveRelatedWork"
            @btnCallback="saveRelatedWorkCallback"/>
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      class="q-mt-sm"
      title="검토"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="moc.committeeReviewItems"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSaveReviewItem"
            :url="saveReviewItemUrl"
            :param="grid2.saveData"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveReviewItem"
            @btnCallback="saveReviewItemCallback"/>
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'moc-review',

  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        committeeReviewItems: [],
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          firstHoldDate: '',  // (최초) 개최일
          firstHoldArea: '',  // (최초) 개최장소
          firstReviewContents: '',  // (최초) 검토내용
          firstReviewResult: '',  // (최초) 검토결과
          professionalHoldDate: '',  // (전문가) 개최일
          professionalHoldArea: '',  // (전문가) 개최장소
          professionalReviewContents: '',  // (전문가) 검토내용
          professionalReviewResult: '',  // (전문가) 검토결과
          finalHoldDate: '',  // (최종) 개최일
          finalHoldArea: '',  // (최종) 개최장소
          finalReviewContents: '',  // (최종) 검토내용
          finalReviewResult: '',  // (최종) 검토결과
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일
          changeKeepFlag: '',  // 변경유지 여부
          changeKeepReason: '',  // 변경유지 사유
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
        },
        relatedWorks: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    disabled2: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    attachInfo: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'MOC_APPROVAL',
          taskKey: '',
        };
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'gubun',
            field: 'gubun',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'holdDate',
            field: 'holdDate',
            label: '개최일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'date',
          },
          {
            name: 'holdArea',
            field: 'holdArea',
            label: '개최장소',
            align: 'left',
            style: 'width:120px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'reviewContents',
            field: 'reviewContents',
            label: '검토내용',
            align: 'left',
            style: 'width:150px',
            type: 'textarea',
            sortable: true,
          },
          {
            name: 'reviewResult',
            field: 'reviewResult',
            label: '검토결과',
            align: 'left',
            style: 'width:150px',
            type: 'textarea',
            sortable: true,
          },
        ],
        data: [],
        height: '390px'
      },
      grid2: {
        columns: [
          {
            name: 'reviewItemName',
            field: 'reviewItemName',
            label: '검토항목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            label: '검토자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewContents',
            field: 'reviewContents',
            label: '검토내용',
            align: 'left',
            style: 'width:400px',
            type: 'textarea',
            sortable: true,
          },
          {
            name: 'reviewCompleteDate',
            field: 'reviewCompleteDate',
            label: '검토완료일',
            align: 'center',
            style: 'width:100px',
            type: 'date',
            sortable: true,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'MOC_REVIEW',
            keyText: 'sopChangeCommitteeReviewItemId',
            sortable: false,
          },
        ],
        saveData: {
          sopMocId: '',
          committeeReviewItems: [],
        },
        height: '350px'
      },
      grid3: {
        columns: [
          {
            name: 'mocRelatedTaskName',
            field: 'mocRelatedTaskName',
            label: '구분',
            align: 'center',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'changeFlag',
            field: 'changeFlag',
            label: '변경/실행여부',
            align: 'center',
            style: 'width:100px',
            type: 'check',
            'false': 'N',
            'true': 'Y',
            sortable: false,
            value: 'N',
            setHeader: true,
          },
          {
            name: 'actionScheduleCompleteDate',
            field: 'actionScheduleCompleteDate',
            label: '완료예정일',
            align: 'center',
            style: 'width:200px',
            type: 'date',
            sortable: false,
            disableTarget: 'changeFlag',
            disableCon: 'Y',
            value: '',
            setHeader: true,
          },
          {
            name: 'actionUserName',
            field: 'actionUserName',
            label: '담당자',
            align: 'center',
            style: 'width:150px',
            type: 'user',
            userId: 'actionUserId',
            deptCd: 'actionDeptCd',
            deptName: 'actionDeptName',
            sortable: false,
            disableTarget: 'changeFlag',
            disableCon: 'Y',
            value: '',
            setHeader: true,
          },
          {
            name: 'checkUserName',
            field: 'checkUserName',
            label: '확인자',
            align: 'center',
            style: 'width:150px',
            type: 'user',
            userId: 'checkUserId',
            sortable: false,
            disableTarget: 'changeFlag',
            disableCon: 'Y',
            value: '',
            setHeader: true,
          },
        ],
        saveData: {
          sopMocId: '',
          relatedWorks: [],
        },
        height: '370px'
      },
      approvItems: [],
      editable: true,
      isSaveComitteeHold: false,
      isSaveApproval: false,
      isSaveNonApproval: false,
      isSaveComitteeHoldApprovalInfo: false,
      isSaveReviewItem: false,
      isSaveRelatedWork: false,
      saveComitteeHoldUrl: '',
      approvalComitteeHoldUrl: '',
      saveReviewItemUrl: '',
      saveRelatedWorkUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalEditable() {
      // return Boolean(this.moc && this.moc.committeeHold && this.moc.committeeHold.sopMocId)
      return Boolean(this.moc)
    }
  },
  methods: {
    init() {
      this.approvItems = [
        { code: 'Y', codeName: this.$language('승인') },
        { code: 'N', codeName: this.$language('불승인') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveComitteeHoldUrl = transactionConfig.sop.moc.committeeHold.save.url
      this.approvalComitteeHoldUrl = transactionConfig.sop.moc.committeeHold.approval.url
      this.saveReviewItemUrl = transactionConfig.sop.moc.committeeReviewItem.save.url
      this.saveRelatedWorkUrl = transactionConfig.sop.moc.relatedWork.save.url
      // code setting
      // list setting
      
      this.setHoldData();
    },
    setHoldData() {
      this.grid.data.push({
        gubun: this.$language('최초'), 
        holdDate: this.moc.committeeHold.firstHoldDate,
        holdArea: this.moc.committeeHold.firstHoldArea,
        reviewContents: this.moc.committeeHold.firstReviewContents,
        reviewResult: this.moc.committeeHold.firstReviewResult,
      })
      this.grid.data.push({
        gubun: this.$language('전문가'), 
        holdDate: this.moc.committeeHold.professionalHoldDate,
        holdArea: this.moc.committeeHold.professionalHoldArea,
        reviewContents: this.moc.committeeHold.professionalReviewContents,
        reviewResult: this.moc.committeeHold.professionalReviewResult,
      })
      this.grid.data.push({
        gubun: this.$language('최종'), 
        holdDate: this.moc.committeeHold.finalHoldDate,
        holdArea: this.moc.committeeHold.finalHoldArea,
        reviewContents: this.moc.committeeHold.finalReviewContents,
        reviewResult: this.moc.committeeHold.finalReviewResult,
      })
      this.saveComitteeHold();
    },
    setTaskKey() {
    },
    saveComitteeHold() {
      // if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
      //   window.getApp.$emit('CONFIRM', {
      //     title: '확인',
      //     message: '저장하시겠습니까?',
      //     
      //     type: 'info', // success / info / warning / error
      //     // 확인 callback 함수
      //     confirmCallback: () => {
            
      //     },
      //     // 취소 callback 함수
      //     cancelCallback: () => {
      //     },
      //   });
      // }
      if (this.moc.committeeHold.sopMocId) {
        this.moc.committeeHold.chgUserId = this.$store.getters.user.userId
        this.moc.committeeHold.editFlag = 'U'
      } else {
        this.moc.committeeHold.sopMocId = this.moc.sopMocId
        this.moc.committeeHold.regUserId = this.$store.getters.user.userId
        this.moc.committeeHold.editFlag = 'C'
      }
      this.moc.committeeHold.firstHoldDate = this.grid.data[0].holdDate
      this.moc.committeeHold.firstHoldArea = this.grid.data[0].holdArea
      this.moc.committeeHold.firstReviewContents = this.grid.data[0].reviewContents
      this.moc.committeeHold.firstReviewResult = this.grid.data[0].reviewResult
      this.moc.committeeHold.professionalHoldDate = this.grid.data[1].holdDate
      this.moc.committeeHold.professionalHoldArea = this.grid.data[1].holdArea
      this.moc.committeeHold.professionalReviewContents = this.grid.data[1].reviewContents
      this.moc.committeeHold.professionalReviewResult = this.grid.data[1].reviewResult
      this.moc.committeeHold.finalHoldDate = this.grid.data[2].holdDate
      this.moc.committeeHold.finalHoldArea = this.grid.data[2].holdArea
      this.moc.committeeHold.finalReviewContents = this.grid.data[2].reviewContents
      this.moc.committeeHold.finalReviewResult = this.grid.data[2].reviewResult
      
      this.isSaveComitteeHold = !this.isSaveComitteeHold
    },
    saveComitteeHoldCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    approvalComitteeHold() {
      // 위원회가 구성되었는지?
      // 개최정보가 최초/전문가/최종 중에 하나 이상 들어갔는지?
      // 최종 진행항목이 결정되었는지?
      // 승인 사유가 있는지?
      let isProgress = true;
      
      if (!this.moc.committeeHold.changeApprovalDate) {
        isProgress = false;
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '승인/불승인 처리일자를 입력하시기 바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      if (!this.moc.committeeHold.changeApprovalReason) {
        isProgress = false;
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '승인 사유를 입력하시기 바랍니다.', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '승인하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.moc.chgUserId = this.$store.getters.user.userId
            this.moc.mocStepCd = (this.moc.mocTypeCd === 'MT00000001' ? 'MS00000015' : 'MT00000020')
            
            this.moc.committeeHold.chgUserId = this.$store.getters.user.userId
            this.moc.committeeHold.changeApprovalFlag = 'Y'

            this.isSaveApproval = !this.isSaveApproval
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    approvalComitteeHoldCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('approvalCallback')
    },
    nonApprovalComitteeHold() {
      if (!this.moc.committeeHold.changeApprovalReason) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '불승인 사유를 입력하시기 바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '불승인하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.moc.committeeHold.chgUserId = this.$store.getters.user.userId
            this.moc.committeeHold.changeApprovalFlag = 'N'
            this.moc.mocStepCd = (this.moc.mocTypeCd === 'MT00000001' ? 'MS00000020' : 'MT00000025')
  
            this.isSaveNonApproval = !this.isSaveNonApproval
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    nonApprovalComitteeHoldCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('approvalCallback')
    },
    saveComitteeHoldApprovalInfo() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.moc.committeeHold.chgUserId = this.$store.getters.user.userId
            this.moc.committeeHold.editFlag = 'U'
            
            this.isSaveComitteeHold = !this.isSaveComitteeHold
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveComitteeHoldApprovalInfoCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    saveReviewItem() {
      if (this.$comm.validTable(this.grid2.columns, this.moc.committeeReviewItems)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid2.saveData.sopMocId = this.moc.sopMocId;
            this.grid2.saveData.committeeReviewItems = this.$_.cloneDeep(this.moc.committeeReviewItems);
            
            this.isSaveReviewItem = !this.isSaveReviewItem
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveReviewItemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    saveRelatedWork() {
      if (this.$comm.validTable(this.grid2.columns, this.moc.committeeReviewItems)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid3.saveData.sopMocId = this.moc.sopMocId;
            this.grid3.saveData.relatedWorks = this.$_.cloneDeep(this.moc.relatedWorks);
            
            this.isSaveRelatedWork = !this.isSaveRelatedWork
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRelatedWorkCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
  }
};
</script>
